import { AuthenticatedTemplate } from "@azure/msal-react";
import { NavigationBar } from "./NavigationBar";
import Footer from "./Footer";

export const PageLayout = (props) => {

    return (
        <>
            <NavigationBar />
            {props.children}
            <Footer />
            {/* <AuthenticatedTemplate>
            </AuthenticatedTemplate> */}
        </>
    );
};
