import React from "react";

const errors = {
    404: "This control number does not exist as {0}.",
    500: "Something went wrong, could not get results for {0}."
}

function ResultsError(props) {

    let errorCode = props.response.status;
    let errorMsg = errors[errorCode] ?? errors[500];
    errorMsg = errorMsg.replace("{0}", props.type)


    return (
        <div>
            <p>{errorMsg}</p>
        </div>
    );
  }

  export default ResultsError;