import React from "react";

import InputParameters from './InputParameters';

function DeductibleInputParameters(props) {
    return (
      <div>
        <p className='deductible-input-params-header'>{props.inputParamsHeader} deductible</p>
        <div className='deductible-input-params-container'>
            <InputParameters 
                handleChangeFunc={props.handleDeductibleAmountChange} 
                labelText= "Amount"
                inputValue={props.deductibleAmount == 0 ? null : props.deductibleAmount} />
            <InputParameters 
                handleChangeFunc={props.handleDeductibleRatingFactorChange} 
                labelText= "Rating factor"
                inputValue={props.deductibleRatingFactor == 0 ? null : props.deductibleRatingFactor} />   
        </div>
        
    </div>



    );
  }

  export default DeductibleInputParameters;