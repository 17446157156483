import React from "react";

function RadioInput(props) {
    return (
      <div 
        className="radio-input-div"
        onChange={props.onChange}>
          <input 
            type="radio"
            value={props.inputName}
            name="dataType"
            checked={props.currentValue == props.inputName}
          /> {props.inputText}
      </div>
    );
  }

  export default RadioInput;