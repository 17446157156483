import React from "react";

function InputParameters(props) {
    return (
      <div className="input-div">
        <label htmlFor="lable">{props.labelText}</label>
        <input
            id="lable"
            onChange={props.handleChangeFunc}
            type="text"
            placeholder="0"
            value={props.inputValue}
        />
      </div>
    );
  }

  export default InputParameters;