import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
//   layout: {
//     padding: {
//         left: 100
//     }
// },
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: 'Explanation Chart',
    },
  },
  // scales: {
  //   xAxes: [{ 
  //     gridLines: { lineWidth: 500 },
  //  }]
// }
};


export function ExplainationChart(props) {


  const explanationDict = props.explanation

  let positiveKeys = [];
  let negativeKeys = [];
  let zeroKeys = [];
  let positiveValues = [];
  let negativeValues = [];
  let zeroValues = [];

  let allValues = [];
  let allKey = [];

  let labels = [];
  

  

  for (let key in explanationDict){

    labels.push(key);
    let value = explanationDict[key];
    allValues.push(value);    
    allKey.push(key);
    
    if (value < 0){
      negativeKeys.push(key)
      negativeValues.push(explanationDict[key]);
    } 
    
    else if (value > 0){
      positiveKeys.push(key)
      positiveValues.push(explanationDict[key]);
    }
    
    else{
      zeroKeys.push(key)
      zeroValues.push(explanationDict[key]);
    }
  }

  var listOfKeyVal = [];
  for (let key of allKey)
  {
    var tuple = [];
    tuple.push(key);
    tuple.push(explanationDict[key]);
    listOfKeyVal.push(tuple)
  }

  listOfKeyVal.sort(function(first, second) {
    return Math.abs(second[1]) - Math.abs(first[1]);
  });

  var i = 0;
  var listOfSortedKeys = [];
  for (let key_val of listOfKeyVal){
    listOfSortedKeys.push(listOfKeyVal[i][0]);
    i++;
  }

  labels = listOfSortedKeys;

  const data = {
    labels,
    datasets: [
      {
        label: 'Low Risk',
        data: listOfSortedKeys.map((key) => {
          let value = explanationDict[key];
          if (value < 0){
            return value;
          } 
          else 
          {
            return 0;
          }
        }),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'High Risk',
        data: listOfSortedKeys.map((key) => {
          let value = explanationDict[key];
          if (value > 0){
            return value;
          } 
          else 
          {
            return 0;
          }
        }),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
  };


  return (
    <div className='explaination-chart'>
      <Bar options={options} data={data} />
  
     </div>)
  
}
