import React, { useState } from 'react'

import VendorResults from './VendorResults';
import ResultsError from './ResultsError';
import InputParameters from './InputParameters';
import DeductibleInputParameters from './DeductibleInputParameters';
import RadioInput from './RadioInput';

import { msalInstance } from "../index";
import { protectedResources } from "../authConfig";

import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

const getToken = async (scopes) => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        account: account,
        scopes: scopes
    });

    return response.accessToken;
}

const VendorParamFormContent = () => {

    // UI effects parameters
    const [isMouseOver, setIsMouseOver] = useState(false);

    // http request params
    const [error, setError] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    // vendor params
    const [type, setType] = useState("submission")
    const [typeResult, setTypeResult] = useState("submission")
    const [controlNumber, setControlNumber] = useState(0);
    const [propertyPremium, setPropertyPremium] = useState(0);
    const [GLPremium, setGLPremium] = useState(0);    
    const [aOPDeductibleAmount, setAOPDeductibleAmount] = useState(0);
    const [aOPDeductibleRatingFactor, setAOPDeductibleRatingFactor] = useState(0);
    const [waterDeductibleAmount, setWaterDeductibleAmount] = useState(0);
    const [waterDeductibleRatingFactor, setWaterDeductibleRatingFactor] = useState(0);
    const [windHailDeductibleAmount, setWindHailDeductibleAmount] = useState(0);
    const [windHailDeductibleRatingFactor, setWindHailDeductibleRatingFactor] = useState(0);
    const [perUnitDeductibleAmount, setPerUnitDeductibleAmount] = useState(0);
    const [perUnitDeductibleRatingFactor, setPerUnitDeductibleRatingFactor] = useState(0);  
    const [controlNumberRequest, setControlNumberRequest] = useState("");
    const [result, setResult] = useState(null);


    function HandleMouseOver() {
        setIsMouseOver(true);
    }
    
    function HandleMouseOut() {
        setIsMouseOver(false);
    }

    function handleControlNumberChange(event) {
        setControlNumber(event.target.value);
    }

    function handlePropertyPremiumChange(event) {
        setPropertyPremium(event.target.value);
    }    

    function handleGLPremiumChange(event) {
        setGLPremium(event.target.value);
    }

    function handleAOPDeductibleAmountChange(event) {
        setAOPDeductibleAmount(event.target.value);
    }

    function handleAOPDeductibleRatingFactorChange(event) {
        setAOPDeductibleRatingFactor(event.target.value);
    }

    function handleWaterDeductibleAmountChange(event) {
        setWaterDeductibleAmount(event.target.value);
    }

    function handleWaterDeductibleRatingFactorChange(event) {
        setWaterDeductibleRatingFactor(event.target.value);
    }

    function handleWindHailDeductibleAmountChange(event) {
        setWindHailDeductibleAmount(event.target.value);
    }

    function handleWindHailDeductibleRatingFactorChange(event) {
        setWindHailDeductibleRatingFactor(event.target.value);
    }

    function handlePerUnitDeductibleAmountChange(event) {
        setPerUnitDeductibleAmount(event.target.value);
    }

    function handlePerUnitDeductibleRatingFactorChange(event) {
        setPerUnitDeductibleRatingFactor(event.target.value);
    }

    function onRadioInputChange(event) {
        setType(event.target.value);
    }

    async function sendFetchReqest(endpoint, headers, queryParams)
    {
        var queryParamsSuffix = "";

        if (queryParams != null)
        {
            const queryParamsList = [] 
            for (const [key, value] of Object.entries(queryParams))
            {
                queryParamsList.push(`${key}=${value}`);
            }
            queryParamsSuffix = `?${queryParamsList.join("&")}`;
        }

        const options = {
            method: "GET",
            headers: headers
        };

        const endpointWithQueryParams = `${endpoint}${queryParamsSuffix}`

        const response = await fetch(endpointWithQueryParams, options)
            .catch(error => console.log("#### ERROR: " + error));

        return response
    }


    async function HandleClick(e) {
        e.preventDefault();

        console.log("App id is: " + process.env.REACT_APP_SMARTRISK_API_ID);

        const accessToken = await getToken(protectedResources.apiRisk.scopes.read);

        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);

        const queryParams = {
            "type": type,
            "Prop Prem": propertyPremium,
            "GL Prem": GLPremium,
            "AOP Deductible Amount": aOPDeductibleAmount,
            "AOP Deductible Rating Factor": aOPDeductibleRatingFactor,
            "Water Deductible Amount": waterDeductibleAmount,
            "Water Deductible Rating Factor": waterDeductibleRatingFactor,
            "Wind and Hail Deductible Amount": windHailDeductibleAmount,
            "Wind and Hail Deductible Rating Factor": windHailDeductibleRatingFactor,
            "Per Unit Deductible Amount": perUnitDeductibleAmount,
            "Per Unit Deductible Rating Factor": perUnitDeductibleRatingFactor
        }

        const endpoint = protectedResources.apiRisk.endpoint + controlNumber;
        const response = await sendFetchReqest(endpoint, headers, queryParams)
        
        try {
            // handle error
            if (response.status != 200) {
                console.log(`Error: response status: ${response.status}`);
                console.log(`Error: response is: ${response}`);
                setError(true);
                setErrorResponse(response);
                setTypeResult(type)
                setIsLoaded(true);
            }
    
            // handle ok response
            else {
                setError(false);
                const resBody = await response.json();

                setControlNumberRequest(controlNumber);
                setResult(resBody);
                setTypeResult(type)
                setIsLoaded(true);

                // setControlNumber(0)
                // setPremium(0)
                // setDeductible(0)

                console.log("isLoaded: "+ isLoaded);
            }
        }
        catch (e) {
            console.log("Could not parst json response, body is probably empty.");
            setError(true);
            setIsLoaded(true);
        }   
    }

    return (
        <div className="vendor-param-container">
        
                <div className="vendor-param-heading">
                    <p>Please enter the following:</p>
                </div>
                
                <form className="vendor-param-form" onSubmit={HandleClick}>                  
                
                    <div className='params-block'>
                        <InputParameters 
                            handleChangeFunc={handleControlNumberChange} 
                            labelText= "Submission control number"
                            inputValue={controlNumber == 0 ? null : controlNumber} />
                        <InputParameters 
                            handleChangeFunc={handlePropertyPremiumChange} 
                            labelText= "Property premium"
                            inputValue={propertyPremium == 0 ? null : propertyPremium} />
                        <InputParameters 
                            handleChangeFunc={handleGLPremiumChange} 
                            labelText= "GL premium"
                            inputValue={GLPremium == 0 ? null : GLPremium} />
                    </div>
                    
                    <div className='params-block'>
                        <DeductibleInputParameters 
                            inputParamsHeader="AOP" 
                            handleDeductibleAmountChange= {handleAOPDeductibleAmountChange}
                            deductibleAmount={aOPDeductibleAmount}
                            handleDeductibleRatingFactorChange = {handleAOPDeductibleRatingFactorChange}
                            deductibleRatingFactor = {aOPDeductibleRatingFactor}/>

                        <DeductibleInputParameters 
                            inputParamsHeader="Water" 
                            handleDeductibleAmountChange= {handleWaterDeductibleAmountChange}
                            deductibleAmount={waterDeductibleAmount}
                            handleDeductibleRatingFactorChange = {handleWaterDeductibleRatingFactorChange}
                            deductibleRatingFactor = {waterDeductibleRatingFactor}/>
                    </div>

                    <div className='params-block'>
                        <DeductibleInputParameters 
                            inputParamsHeader="Wind and hail" 
                            handleDeductibleAmountChange= {handleWindHailDeductibleAmountChange}
                            deductibleAmount={windHailDeductibleAmount}
                            handleDeductibleRatingFactorChange = {handleWindHailDeductibleRatingFactorChange}
                            deductibleRatingFactor = {windHailDeductibleRatingFactor}/>

                        <DeductibleInputParameters 
                            inputParamsHeader="Per unit" 
                            handleDeductibleAmountChange= {handlePerUnitDeductibleAmountChange}
                            deductibleAmount={perUnitDeductibleAmount}
                            handleDeductibleRatingFactorChange = {handlePerUnitDeductibleRatingFactorChange}
                            deductibleRatingFactor = {perUnitDeductibleRatingFactor}/>
                    </div>
                    
                    <button 
                        type="submit"
                        onMouseOver={HandleMouseOver}
                        onMouseOut={HandleMouseOut}
                        style={{backgroundColor: isMouseOver ? "#8D93AB" : "#D6E0F0"}}>
                        Click to get the loss ratio
                    </button>
                    
                </form>
                <br />

            {error ? <ResultsError response={errorResponse} type={typeResult}/> : isLoaded ? <VendorResults result={result} controlNumber={controlNumberRequest} type={typeResult}/> : <div />}

        </div>
    )
};


export const VendorParamForm2 = () => {
    const authRequest = {
        ...loginRequest
    };
  
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
        >
            <VendorParamFormContent />
        </MsalAuthenticationTemplate>
      )
  };