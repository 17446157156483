import React from "react";
import { ExplainationChart } from "./ExplainationChart";

function GetColorAndDescriptionForRisk(risk)
{
    if (risk >= 0 && risk < 0.4)
    {
        return <span className="risk-result-low">low</span>
    }
    
    if (risk >= 0.4 && risk < 0.8)
    {
        return <span className="risk-result-medium">medium</span>
    }

    return <span className="risk-result-high">high</span>
}

function VendorResults(props) {

    console.log(props)
    if (!props.result) {
        // TODO
        return (
            <div>
                <p>Testeste</p>
            </div> 
        )
    }
    else {
        return (
            <div className="results-container">
                <p className="ControlNumber">Control Number: {props.controlNumber}</p>             
                
                <p className="results-info">Address: {props.result.address}</p>
                <p className="results-info">TIV: {props.result.tiv}</p>
                <p className="results-info">Building Count: {props.result.building_count}</p>
                <p className="results-info">Building Age: {props.result.building_age}</p>
                <p className="risk-result">Loss Ratio: {props.result.calculated_loss}</p>
            </div>
        )
    }
}

export default VendorResults;