import React from 'react'

function Footer() { 
    const currentYear = new Date().getFullYear();
    
    return (
        <footer className="footer-section">
            <p className="copyrights-text">Copyright © {currentYear} Arden</p>
        </footer>
    );
}

export default Footer